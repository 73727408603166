import Nav from "react-bootstrap/Nav";
import HorizontalScroller from "../controls/horizontalscroller";
import ScheduleGameTypeSelector from "./schedulegametypeselector";
import HostLevelSelector from "../hostlevelselector";
import PlaceSelector from "../placeselector";
import GameLevelSelector from "../gamelevelselector";
import { isMeet4USite } from "../../lib/coreutils";
import ClubSelector from "../clubselector";

function ScheduleDate({ date }) {
    if (date.shortcut_date) {
        return <span className={date.date == "1970-01-01" ? "text-danger" : null}>{date.shortcut_date}</span>;
    }
    return (
        <>
            <span className="d-mobile-only">
                {date.weekday} ({date.menu_date})
            </span>
            <span className="d-desktop-only">
                <span className="schedule-date-selector__date-long">{date.menu_date_long}</span>
                <br />
                <span
                    className={
                        "schedule-date-selector__weekday" +
                        (date.is_holiday ? " schedule-date-selector__weekday--holiday" : "")
                    }
                >
                    {date.weekday}
                </span>
            </span>
        </>
    );
}

export default function ScheduleDateSelector({
    dates,
    selectedDate,
    enrollmentMatrix,
    gameTypes,
    selectedGameType,
    clubs,
    selectedClub,
    places,
    selectedPlace,
    gameLevels,
    selectedGameLevel,
    hostLevels,
    selectedHostLevel,
    city,
    showEnrollmentLink,
    hideArchiveLink,
    onDateSelect,
    onGameTypeSelect,
    onClubSelect,
    onPlaceSelect,
    onGameLevelSelect,
    onHostLevelSelect,
}) {
    const getActiveDates = ({ selectedPlace, selectedGameType, selectedGameLevel, selectedClub }) => {
        if (enrollmentMatrix) {
            const c = clubs ? clubs[selectedClub]?.id : null;
            const p = places[selectedPlace]?.id;
            const gt = gameTypes[selectedGameType]?.id;
            const gl = gameLevels[selectedGameLevel]?.id;

            const datesSet = new Set();
            for (const enrollment of enrollmentMatrix) {
                if (c && c !== "" && enrollment.club !== c) {
                    continue;
                }
                if (p && p !== "" && enrollment.place !== p) {
                    continue;
                }
                if (gt && gt !== "" && enrollment.game_type !== gt) {
                    continue;
                }
                if (gl && gl !== "" && enrollment.game_level !== gl) {
                    continue;
                }
                for (const date of enrollment.dates) {
                    datesSet.add(date);
                }
            }
            // ZERO_DATE и ALL_DAYS_DATE на бэкенде
            const activeDates = ["1970-01-01", "1970-01-02"].concat(Array.from(datesSet));
            activeDates.sort();
            return activeDates;
        }

        return gameTypes.length > 0
            ? gameTypes[selectedGameType].enrollment_dates
            : dates.map((dateObj) => dateObj.date);
    };

    const adjustSelectedDate = ({ selectedPlace, selectedGameType, selectedGameLevel, selectedClub }) => {
        const activeDates = getActiveDates({
            selectedPlace,
            selectedGameType,
            selectedGameLevel,
            selectedClub,
        });
        const date = dates[selectedDate]?.date;

        if (activeDates.indexOf(date) === -1) {
            const availableDates = (activeDates.length > 0 ? activeDates : dates.map((d) => d.date)).filter(
                (d) => d != "1970-01-01" && d != "1970-01-02"
            );
            const firstAvailableDate = availableDates[0];
            if (firstAvailableDate) {
                const newSelectedDate = dates.findIndex((d) => d.date == firstAvailableDate);
                if (newSelectedDate !== -1) {
                    onDateSelect(newSelectedDate);
                }
            }
        }
    };

    const onMenuDateSelect = (date) => {
        // Если числовая строка, значит выбрали какую-то дату.  Если нет, значит щелкнули по архиву или полному расписанию
        if (/^\d+$/.test(date)) {
            onDateSelect(date);
        }
    };

    const onMenuClubSelect = (club) => {
        adjustSelectedDate({ selectedPlace, selectedGameType, selectedGameLevel, selectedClub: club });
        onClubSelect(club);
    };

    const onMenuPlaceSelect = (place) => {
        adjustSelectedDate({ selectedPlace: place, selectedGameType, selectedGameLevel, selectedClub });
        onPlaceSelect(place);
    };

    const onMenuGameTypeSelect = (gameType) => {
        adjustSelectedDate({ selectedPlace, selectedGameType: gameType, selectedGameLevel, selectedClub });
        onGameTypeSelect(gameType);
    };

    const onMenuGameLevelSelect = (gameLevel) => {
        adjustSelectedDate({ selectedPlace, selectedGameType, selectedGameLevel: gameLevel, selectedClub });
        onGameLevelSelect(gameLevel);
    };

    const activeDates = getActiveDates({ selectedPlace, selectedGameType, selectedGameLevel, selectedClub });

    const isDisabled = (date) => {
        return activeDates.indexOf(date) === -1;
    };

    return (
        <>
            {isMeet4USite() ? (
                <>
                    <ScheduleGameTypeSelector
                        gameTypes={gameTypes}
                        selectedGameType={selectedGameType}
                        onSelect={onMenuGameTypeSelect}
                    />
                    <ClubSelector clubs={clubs} selectedClub={selectedClub} onSelect={onMenuClubSelect} />
                    <PlaceSelector places={places} selectedPlace={selectedPlace} onSelect={onMenuPlaceSelect} />
                </>
            ) : (
                <>
                    <PlaceSelector places={places} selectedPlace={selectedPlace} onSelect={onMenuPlaceSelect} />
                    <ScheduleGameTypeSelector
                        gameTypes={gameTypes}
                        selectedGameType={selectedGameType}
                        onSelect={onMenuGameTypeSelect}
                    />
                    <GameLevelSelector
                        gameLevels={gameLevels}
                        selectedGameLevel={selectedGameLevel}
                        onSelect={onMenuGameLevelSelect}
                    />
                    <HostLevelSelector
                        hostLevels={hostLevels}
                        selectedHostLevel={selectedHostLevel}
                        onSelect={onHostLevelSelect}
                    />
                </>
            )}

            {dates.length > 1 && (
                <div className="schedule-date-selector">
                    <HorizontalScroller scrollDelta={200} stateKey="schedule-date-selector">
                        <Nav
                            as="ul"
                            className="nav nav-pills schedule-date-selector__nav-pills"
                            activeKey={selectedDate}
                            onSelect={onMenuDateSelect}
                        >
                            {!hideArchiveLink && (
                                <Nav.Item as="li" className="schedule-date-selector__archive">
                                    <Nav.Link href={`/${city.slug}/archive/`}>
                                        <span>Архив</span>
                                    </Nav.Link>
                                </Nav.Item>
                            )}
                            {dates.map((date, idx) => (
                                <Nav.Item as="li" key={date.date}>
                                    <Nav.Link eventKey={idx} disabled={isDisabled(date.date)}>
                                        <ScheduleDate date={date} />
                                    </Nav.Link>
                                </Nav.Item>
                            ))}
                            {showEnrollmentLink && (
                                <Nav.Item as="li" className="schedule-date-selector__enrollment">
                                    <Nav.Link href={`/${city.slug}/enrollment/`}>
                                        <span>Полное расписание</span>
                                    </Nav.Link>
                                </Nav.Item>
                            )}
                        </Nav>
                    </HorizontalScroller>
                </div>
            )}
        </>
    );
}
