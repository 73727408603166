import Nav from "react-bootstrap/Nav";
import HorizontalScroller from "./controls/horizontalscroller";

export default function HostLevelSelector({ hostLevels, selectedHostLevel, onSelect }) {
    if (!hostLevels || hostLevels.length < 2) {
        return null;
    }

    return (
        <div className="schedule-date-selector">
            <HorizontalScroller scrollDelta={200} stateKey="host-level-selector">
            <Nav
                as="ul"
                className="nav nav-pills schedule-date-selector__nav-pills schedule-date-selector__nav-pills--with-bg"
                activeKey={selectedHostLevel}
                onSelect={onSelect}
            >
                {hostLevels.map((hostLevel, idx) => (
                    <Nav.Item as="li" key={hostLevel.id}>
                        <Nav.Link eventKey={idx}>
                            <span>{hostLevel.name}</span>
                        </Nav.Link>
                    </Nav.Item>
                ))}
            </Nav>
        </HorizontalScroller>
        </div >
    );
}
