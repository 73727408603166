import Nav from "react-bootstrap/Nav";
import HorizontalScroller from "./controls/horizontalscroller";

export default function ClubSelector({ clubs, selectedClub, onSelect }) {
    if (!clubs || clubs.length < 2) {
        return null;
    }

    return (
        <div className="schedule-date-selector">
            <HorizontalScroller scrollDelta={200} stateKey="club-selector">
                <Nav
                    as="ul"
                    className="nav nav-pills schedule-date-selector__nav-pills schedule-date-selector__nav-pills--with-bg"
                    activeKey={selectedClub}
                    onSelect={onSelect}
                >
                    {clubs.map((club, idx) => (
                        <Nav.Item as="li" key={club.id}>
                            <Nav.Link eventKey={idx}>
                                <span>{club.name}</span>
                            </Nav.Link>
                        </Nav.Item>
                    ))}
                </Nav>
            </HorizontalScroller>
        </div>
    );
}
