import Nav from "react-bootstrap/Nav";
import HorizontalScroller from "../controls/horizontalscroller";

export default function ScheduleGameTypeSelector({ gameTypes, selectedGameType, onSelect }) {
    if (!gameTypes || gameTypes.length < 2) {
        return null;
    }

    return (
        <div className="schedule-date-selector">
            <HorizontalScroller scrollDelta={200} stateKey="schedule-gametype-selector">
                <Nav
                    as="ul"
                    className="nav nav-pills schedule-date-selector__nav-pills schedule-date-selector__nav-pills--with-bg"
                    activeKey={selectedGameType}
                    onSelect={onSelect}
                >
                    {gameTypes.map((gameType, idx) => (
                        <Nav.Item as="li" key={gameType.id}>
                            <Nav.Link eventKey={idx}>
                                <span>{gameType.name}</span>
                            </Nav.Link>
                        </Nav.Item>
                    ))}
                </Nav>
            </HorizontalScroller>
        </div>
    );
}
