import { useEffect, useRef, useState } from "react";

function setPositionState(stateKey, scrollPosition) {
    if (typeof window == "undefined") {
        return;
    }
    window.horizontalStates = window.horizontalStates || {};
    window.horizontalStates[stateKey] = scrollPosition;
}

function getPositionState(stateKey) {
    if (typeof window == "undefined" || !window.horizontalStates) {
        return 0;
    }
    return window.horizontalStates[stateKey] || 0;
}

export default function HorizontalScroller({ scrollDelta, stateKey, children }) {
    const [rerender, setRerender] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(getPositionState(stateKey));
    const scrollBodyRef = useRef(null);
    const scrollWrapperRef = useRef(null);

    useEffect(() => {
        setRerender(true); // инициируем рендеринг на клиенте, чтобы обновились *ArrowVisible
    }, []);

    useEffect(() => {
        scrollWrapperRef.current.style.left = scrollPosition + "px";
    }, [scrollPosition]);

    const scrollLeft = () => {
        const newPosition = scrollPosition + scrollDelta;
        setScrollPosition(newPosition);
        setPositionState(stateKey, newPosition);
    };
    const scrollRight = () => {
        const newPosition = scrollPosition - scrollDelta;
        setScrollPosition(newPosition);
        setPositionState(stateKey, newPosition);
    };

    const leftArrowVisible = scrollPosition < 0;
    const rightArrowVisible =
        scrollBodyRef.current &&
        scrollWrapperRef.current &&
        scrollBodyRef.current.offsetWidth < scrollWrapperRef.current.scrollWidth + scrollPosition;

    return (
        <div className="horizontal-scroller">
            <button
                className={
                    "horizontal-scroller__arrow horizontal-scroller__arrow--left" + (leftArrowVisible ? " visible" : "")
                }
                onClick={scrollLeft}
            >
                <img src="/img/icons/icon-chevron-left.svg" alt="" />
            </button>
            <div className="horizontal-scroller__body" ref={scrollBodyRef}>
                <div className="horizontal-scroller__wrapper" ref={scrollWrapperRef}>
                    {children}
                </div>
            </div>
            <button
                className={
                    "horizontal-scroller__arrow horizontal-scroller__arrow--right" +
                    (rightArrowVisible ? " visible" : "")
                }
                onClick={scrollRight}
            >
                <img src="/img/icons/icon-chevron-right.svg" alt ="" />
            </button>
        </div>
    );
}
